export enum SellingPlanInterval {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  YEAR = 'YEAR',
}

export enum SellingPlanPricingPolicyAdjustmentType {
  NONE = '',
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
  PRICE = 'PRICE',
}

export type SellingPlanType = 'basic' | 'prepaid' | 'anchors' | 'advanced'
