import { useAppBridge } from '@shopify/app-bridge-react'
import {
  Banner,
  Button,
  ButtonProps,
  LegacyStack,
  Link,
  Select,
  Spinner,
  TextField,
  Toast as PolarisToast,
} from '@shopify/polaris'
import { DateStyle, I18n, useI18n } from '@shopify/react-i18n'
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { SellingPlanAnchor } from './anchor-models'
import { Result } from './api'
import { CustomerCreditCard, LineItem } from './subscription-models'
import {
  FeatureFlags,
  PricingPlanFeatures,
  ShopFlags,
  SystemContext,
  SystemData,
} from './SystemData'

export const anyWindow = window as any

export const useSystemData = () => useContext(SystemContext)

export const useFeatureFlag = (flag: keyof FeatureFlags): boolean => {
  const data = useSystemData()
  return data ? data.featureFlags[flag] : false
}

export const useShopFlag = (flag: keyof ShopFlags): any => {
  const data = useSystemData()
  return data ? data.flags[flag] : false
}

export const useShopPermission = (feature: keyof PricingPlanFeatures): boolean => {
  const data = useSystemData()

  if (!data?.pricingPlan) {
    return true
  }

  return data.pricingPlan[feature]
}

export const getShopTimeZone = (systemData?: SystemData) => {
  return systemData?.shopData.iana_timezone
}

export const useShopTimeZone = (): string | undefined => getShopTimeZone(useSystemData())

export const gidToId = (gidOrId: string | number): string =>
  `${gidOrId}`.replace(/gid:\/\/shopify\/\w+\//, '')

export const gidToNumericId = (gidOrId: string | number): number => parseInt(gidToId(gidOrId))

export const idToGid = (id: string | number, type: string) => `gid://shopify/${type}/${id}`

export const capitalize = (word: string): string =>
  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()

export const capitalizeUnderscores = (text: string): string =>
  text.split('_').map(capitalize).join(' ')

export const capitalizeHyphens = (text: string): string => text.split('-').map(capitalize).join(' ')

export const pluralize = (count: number, singular: string, plural: string = singular + 's') =>
  [1, -1].includes(count) ? singular : plural

export const splitOptionalString = (x?: string, sep: string = ','): string[] => {
  x = (x ?? '').trim()
  return x.length > 0 ? x.split(sep) : []
}

export const truncateString = (str: string, length: number) => {
  if (str.length > length) {
    return <>{str.slice(0, length)}&hellip;</>
  } else {
    return str
  }
}

export const countPrefix = (count: number, showOne: boolean = true) =>
  count === 1 && !showOne ? '' : `${count} `

export const suffix = (text: string, length: number): string =>
  length >= text.length ? text : text.slice(text.length - length)

export const range = (first: number, last: number) =>
  Array.from({ length: last - first + 1 }, (_, i) => i + first)

export const mergeArrays = <T extends any>(first: T[], second: T[]) => first.concat(second)

export const creditCardLastDigits = (card: CustomerCreditCard): string => {
  if (card.lastDigits) {
    return card.lastDigits
  }

  if (card.maskedNumber) {
    return suffix(card.maskedNumber, 4)
  }

  return ''
}

export const sortAnchors = (anchors: SellingPlanAnchor[]) =>
  anchors.sort((a, b) => {
    if (a.month !== b.month && a.month !== null && b.month !== null) {
      return a.month - b.month
    }

    return a.day - b.day
  })

export const escapeHtml = (unsafe: string): string =>
  unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')

export const formatMoney = (cents: number, currency: string = 'USD'): string =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(cents / 100)

export const formatMoneyAmount = (amount: any): string => {
  amount = `${amount || 0}`

  const parts = amount.split('.')

  if (parts.length === 1) {
    return `${amount}.00`
  }

  if (parts[1].length === 1) {
    return `${amount}0`
  }

  return amount
}

export const formatPaymentMethodBrand = (brand: string): string => {
  if (brand === 'jcb') {
    return 'JCB'
  }

  if (brand === 'shop_pay') {
    return 'Card'
  }

  return capitalizeUnderscores(brand)
}

export const formatOrdinal = (n: number): string => {
  const last1 = n % 10
  const last2 = n % 100

  if (last1 === 1 && last2 !== 11) {
    return n.toString() + 'st'
  }

  if (last1 === 2 && last2 !== 12) {
    return n.toString() + 'nd'
  }

  if (last1 === 3 && last2 !== 13) {
    return n.toString() + 'rd'
  }

  return n.toString() + 'th'
}

const nlRex = /(\r\n|\r|\n)/g

export const nl2br = (text: string) =>
  text.split(nlRex).map((line, idx) => (line.match(nlRex) ? <br key={idx} /> : line))

export const shouldHandleLinkClickEvent = (ev: React.MouseEvent | MouseEvent) =>
  ev.button === 0 && !ev.defaultPrevented && !(ev.metaKey || ev.altKey || ev.ctrlKey || ev.shiftKey)

export const AdminLink = ({ url, children }: { url: string; children: any }) => {
  const myUrl = url.startsWith('http') ? url : `shopify://admin${url}`

  return <a className="Polaris-Link" href={myUrl} children={children} target="_top" />
}

export const ExternalAdminLink = ({ url, children }: { url: string; children: any }) => {
  const systemData = useSystemData()
  const myUrl = url.startsWith('http') ? url : `https://${systemData?.domain || ''}/admin${url}`

  return (
    <a className="Polaris-Link" href={myUrl} target="_blank" rel="noreferrer" children={children} />
  )
}

interface InternalLinkProps
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  children: any
  url: string
}

export const InternalLink = ({ url, children, ...props }: InternalLinkProps) => {
  const history = useHistory()
  const systemData = useSystemData()

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (shouldHandleLinkClickEvent(event)) {
        event.preventDefault()
        history.push(url)
      }
    },
    [history, url]
  )

  const href = systemData
    ? `https://${systemData.domain}/admin/apps/${systemData.shopifyAppStub}${url}`
    : undefined

  return (
    <a className="Polaris-Link" onClick={onClick} href={href} {...props}>
      {children}
    </a>
  )
}

type ExternalLinkProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>

export const ExternalLink = (props: ExternalLinkProps) => (
  <a
    className={props.className ?? 'Polaris-Link'}
    target={props.target ?? '_blank'}
    rel={props.rel ?? 'noopener noreferrer'}
    {...props}
  >
    {props.children}
  </a>
)

export const CustomerLink = ({ gid, name }: { gid: string; name: string }) => (
  <AdminLink url={`/customers/${gidToId(gid)}`}>{name}</AdminLink>
)

export const OrderLink = ({ gid, children }: { gid: string; children: any }) => (
  <AdminLink url={`/orders/${gidToId(gid)}`} children={children} />
)

export const LineItemProductLink = ({ item }: { item: LineItem }) => {
  const adminUrl = item.displayData?.adminUrl
  const title = item.displayData?.title || item.title
  return adminUrl ? <AdminLink url={adminUrl}>{title}</AdminLink> : <>{title}</>
}

export const showIntercom = (message?: string) => {
  const intercom = (window as any).Intercom

  if (intercom) {
    intercom('showNewMessage', message)
  }
}

export const ShowIntercomLink = ({ message, children }: { message?: string; children: any }) => (
  <Link onClick={() => showIntercom(message)} children={children} />
)

export const ShowIntercomButton = ({
  message,
  children,
  ...buttonProps
}: { message?: string; children: any } & ButtonProps) => (
  <Button onClick={() => showIntercom(message)} {...buttonProps}>
    {children}
  </Button>
)

export const ExternalRedirect = (props: { url?: string; path?: string; active: boolean }) => {
  const systemData = useSystemData()
  const shopifyHost = (window as any).shopifyHost

  const url =
    props.url ??
    `${window.location.origin}${props.path}?shop=${systemData?.domain || ''}&host=${shopifyHost}`

  useEffect(() => {
    if (props.active) {
      window.open(url, '_top')
    }
  }, [props, url])

  return <></>
}

export const CommaList = ({ children }: { children: any[] }) => {
  return (
    <>
      {children.map((item, index) => (
        <React.Fragment key={index}>
          {index > 0 && ', '}
          {item}
        </React.Fragment>
      ))}
    </>
  )
}

export const fragmentList = (nodes: React.ReactNode[]): React.ReactNode[] =>
  nodes.map((node, index) => <React.Fragment key={index}>{node}</React.Fragment>)

export const CenteredSpinner = () => (
  <LegacyStack distribution="center">
    <Spinner />
  </LegacyStack>
)

export const daysEqual = (d1: Date, d2: Date, tz: string) =>
  d1.toLocaleDateString('en-US', { timeZone: tz }) ===
  d2.toLocaleDateString('en-US', { timeZone: tz })

export const formatLocalISODate = (d: Date) => {
  const pad = (n: number) => (n < 10 ? `0${n}` : `${n}`)
  return [pad(d.getFullYear()), pad(d.getMonth() + 1), pad(d.getDate())].join('-')
}

export type FormattedDateFormat = Intl.DateTimeFormatOptions & { style?: DateStyle }

export const FormattedDate = ({
  date,
  format,
  titleFormat,
}: {
  date: Date
  format: FormattedDateFormat
  titleFormat?: FormattedDateFormat
}) => {
  const [i18n] = useI18n()

  return (
    <span title={i18n.formatDate(date, titleFormat ?? format)}>
      {i18n.formatDate(date, format)}
    </span>
  )
}

export const ShortDate = ({ date }: { date: Date | string }) => (
  <FormattedDate
    date={date instanceof Date ? date : new Date(date)}
    format={{ style: DateStyle.Short }}
    titleFormat={{ style: DateStyle.Long, hour: 'numeric', minute: 'numeric' }}
  />
)

export const ShortDateTime = ({ date }: { date: Date | string }) => (
  <FormattedDate
    date={date instanceof Date ? date : new Date(date)}
    format={{ style: DateStyle.Long, hour: 'numeric', minute: 'numeric' }}
  />
)

export const formatShortDate = (date: Date | string, i18n: I18n) =>
  i18n.formatDate(new Date(date), { style: DateStyle.Short })

export interface DialogActivator {
  open: boolean
  show: () => any
  close: () => any
  showCounter: number
}

export const useDialogActivator = (): DialogActivator => {
  const [showCounter, setShowCounter] = useState(0)
  const [open, setOpen] = useState<boolean>(false)
  const show = useCallback(() => {
    setShowCounter(showCounter + 1)
    setOpen(true)
  }, [showCounter])
  const close = useCallback(() => setOpen(false), [])

  return useMemo(() => ({ open, show, close, showCounter }), [open, show, close, showCounter])
}

export const useQuery = () => new URLSearchParams(useLocation().search)

export const queryString = (params: any, trimValues: boolean = false) => {
  const sp = new URLSearchParams()

  for (const key in params) {
    if (params[key] !== undefined && params[key] !== null) {
      const val = trimValues && typeof params[key] === 'string' ? params[key].trim() : params[key]
      sp.set(key, val)
    }
  }

  return sp.toString()
}

export const useFormikHandleChangeCallback = (setFieldValue: (id: string, val: any) => any) =>
  useCallback((value: any, id: string) => setFieldValue(id, value), [setFieldValue])

export const useFormikHandleNumberChangeCallback = (setFieldValue: (id: string, val: any) => any) =>
  useCallback((value: any, id: string) => setFieldValue(id, Number(value)), [setFieldValue])

export const useFormikHandleNumberFromStringChangeCallback = (
  setFieldValue: (id: string, val: any) => any
) =>
  useCallback(
    (value: any, id: string) => setFieldValue(id, value ? Number(value) : null),
    [setFieldValue]
  )

export const MemoedSelect = React.memo(Select)
export const MemoedTextField = React.memo(TextField)

export const useDebounced = <T,>(value: T, timeout: number) => {
  const [state, setState] = useState(value)

  useEffect(() => {
    const id = setTimeout(() => setState(value), timeout)
    return () => clearTimeout(id)
  }, [value, timeout])

  return state
}

export const useToggle = (state: boolean) => useReducer((state: boolean) => !state, state)

export type NonEmptyArray<T> = [T, ...T[]]

export const pwCache = {
  subscriptionFilter: {},
  sellingPlanNames: {} as Record<string, string>,
  referralCodeSubmitted: false,
}

declare global {
  interface Array<T> {
    pwUnique(): Array<T>
  }
}

Object.defineProperty(Array.prototype, 'pwUnique', {
  writable: true,
  configurable: true,
  value: function <T>(this: T[]) {
    const used = new Set<T>()
    const ret: T[] = []

    this.forEach((el) => {
      if (!used.has(el)) {
        used.add(el)
        ret.push(el)
      }
    })

    return ret
  },
})

export const Toast = (props: {
  content: string
  duration?: number
  error?: boolean
  onDismiss(): void
}) => (anyWindow.adminMode ? <PolarisToast {...props} /> : <AppBridgeToast {...props} />)

export const AppBridgeToast = (props: {
  content: string
  duration?: number
  error?: boolean
  onDismiss(): void
}) => {
  const shopify = useAppBridge()

  shopify.toast.show(props.content, {
    isError: props.error,
    onDismiss: props.onDismiss,
  })

  return null
}

export const ResultToast = ({
  result,
  setResult,
  showOnPermanent = false,
}: {
  result: Result<any> | null
  setResult: (res: null) => any
  // We shouldn't show toasts for permanent errors but in some places there's no room for the banner
  showOnPermanent?: boolean
}) =>
  result && result.message && (result.temporary || showOnPermanent) ? (
    <Toast
      content={result.message}
      error={result.status !== 'success'}
      onDismiss={() => setResult(null)}
    />
  ) : null

export const ResultBanner = ({
  result,
  setResult,
  bottomMargin = true,
}: {
  result: Result<any> | null
  setResult: (res: null) => any
  bottomMargin?: boolean
}) =>
  result && result.message && !result.temporary && !result.field ? (
    <>
      <Banner
        status={result.status === 'success' ? 'success' : 'critical'}
        onDismiss={() => setResult(null)}
      >
        <p>
          {result.status !== 'success' && 'Error: '}
          {result.message}
        </p>
      </Banner>
      {bottomMargin && <br />}
    </>
  ) : null

export const ResultToastOrBanner = ({
  result,
  setResult,
  bottomMargin = true,
}: {
  result: Result<any> | null
  setResult: (res: null) => any
  bottomMargin?: boolean
}) => (
  <>
    <ResultToast result={result} setResult={setResult} />
    <ResultBanner result={result} setResult={setResult} bottomMargin={bottomMargin} />
  </>
)

export const resultErrorField = (res: Result<any> | null, field: string) =>
  res?.status !== 'success' && res?.field === field ? res.message ?? 'Invalid value' : null

export const resultErrorFieldStartsWith = (res: Result<any> | null, fieldStartsWith: string) =>
  res?.status !== 'success' && res?.field?.startsWith(fieldStartsWith)
    ? res.message ?? 'Invalid value'
    : null
