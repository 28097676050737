import { HorizontalStack, Icon, LegacyStack, Modal, Tooltip } from '@shopify/polaris'
import { AlertMinor } from '@shopify/polaris-icons'
import React from 'react'

const basicWorkflowTagVariablesTooltips: Record<string, string | null> = {
  subscription_id: null,
  subscription_created_at: null,
  subscription_created_at_day: null,
  subscription_created_at_month: null,
  subscription_created_at_year: null,
  subscription_initial_order_number: null,
  billing_interval_type: null,
  billing_interval_count: null,
  billing_anchor: 'Variable not supported on multiple anchor plans',
  billing_anchor_weekday: 'Variable not supported on multiple anchor plans',
  billing_anchor_day_of_month: 'Variable not supported on multiple anchor plans',
  billing_anchor_month: 'Variable not supported on multiple anchor plans',
  delivery_interval_count: null,
}

const basicWorkflowTagVariables = Object.keys(basicWorkflowTagVariablesTooltips)

const orderWorkflowTagVariablesTooltips: Record<string, string | null> = {
  payment_number: null,
}

const orderWorkflowTagVariables = Object.keys(orderWorkflowTagVariablesTooltips)

const VariablesStack = ({ variables }: { variables: string[] }) => (
  <LegacyStack vertical spacing="extraTight">
    {variables.map((variable, index) => (
      <HorizontalStack gap="2">
        <code
          key={`variable-${index}`}
          style={{ background: '#ebeef0', padding: '0.1em' }}
        >{`{{${variable}}}`}</code>
        {basicWorkflowTagVariablesTooltips[variable] && (
          <Tooltip content={basicWorkflowTagVariablesTooltips[variable]} dismissOnMouseOut={false}>
            <Icon source={AlertMinor} color="critical" />
          </Tooltip>
        )}
      </HorizontalStack>
    ))}
  </LegacyStack>
)

export const WorkflowVariablesModal = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => any
}) => (
  <Modal open={open} onClose={onClose} sectioned title="Variables">
    <LegacyStack vertical>
      <p>You can use the following variables in your tags:</p>
      <VariablesStack variables={basicWorkflowTagVariables} />
      <p>
        The following ones are available only on <b>Subscription is created</b> and{' '}
        <b>Subscription payment succeeds</b> events:
      </p>
      <VariablesStack variables={orderWorkflowTagVariables} />
    </LegacyStack>
  </Modal>
)
