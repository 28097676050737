import {
  Banner,
  Button,
  FormLayout,
  HorizontalStack,
  LegacyStack,
  Modal,
  SelectOption,
  Text,
} from '@shopify/polaris'
import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  anchorMonthNames,
  anchorWeekDayNames,
  SellingPlanAnchor,
  SellingPlanAnchorType,
  SellingPlanPreAnchorBehavior,
} from './anchor-models'
import {
  DialogActivator,
  formatOrdinal,
  MemoedSelect,
  mergeArrays,
  pluralize,
  range,
  useShopFlag,
} from './helpers'
import { HelpLabel } from './HelpIcon'
import {
  MemoedNumericSelect,
  MemoedOptionalNumericSelect,
  NumericSelectOption,
  OptionalNumericSelectOption,
} from './NumericSelect'
import { SellingPlanInterval } from './plan-models'
import { PrepaidScopesNeededModal } from './PrepaidScopeNeededModal'

const intervalAnchorTypes: Record<string, SellingPlanAnchorType> = {
  [SellingPlanInterval.WEEK]: 'WEEKDAY',
  [SellingPlanInterval.MONTH]: 'MONTHDAY',
  [SellingPlanInterval.YEAR]: 'YEARDAY',
}

const weekDayOptions: OptionalNumericSelectOption[] = range(1, 7).map((i) => ({
  label: anchorWeekDayNames[i]!,
  value: i,
}))

const monthDayOptions = range(1, 31).map((i) => ({
  label: `${formatOrdinal(i)} of the month`,
  value: i,
})) as OptionalNumericSelectOption[]

const yearDayOptions = range(1, 31).map((i) => ({
  label: formatOrdinal(i),
  value: i,
})) as OptionalNumericSelectOption[]

const dayOptions: Record<string, OptionalNumericSelectOption[]> = {
  [SellingPlanInterval.WEEK]: weekDayOptions,
  [SellingPlanInterval.MONTH]: monthDayOptions,
  [SellingPlanInterval.YEAR]: yearDayOptions,
}

const monthOptions: NumericSelectOption[] = range(1, 12).map((i) => ({
  label: anchorMonthNames[i]!,
  value: i,
}))

const anchorDayHelpLabel = (
  <HelpLabel tooltip="Schedule deliveries for specified day">Anchor day</HelpLabel>
)

interface AnchorFieldProps {
  anchor: SellingPlanAnchor | null
  onChange: (anchor: SellingPlanAnchor) => any
  interval: SellingPlanInterval
  labelHidden: boolean
  error: boolean
}

export const AnchorField = ({
  anchor,
  onChange,
  interval,
  labelHidden,
  error,
}: AnchorFieldProps) => {
  const newAnchorType = intervalAnchorTypes[interval]
  const newAnchor = {
    day: anchor?.day ?? 1,
    month: anchor?.month ?? (newAnchorType === 'YEARDAY' ? 1 : null),
    type: intervalAnchorTypes[interval],
  }

  return (
    <LegacyStack distribution="fillEvenly">
      <MemoedOptionalNumericSelect
        label={anchorDayHelpLabel}
        labelHidden={labelHidden}
        options={dayOptions[interval]}
        onChange={(day: number) => onChange({ ...newAnchor, day })}
        value={anchor?.day ?? null}
        error={error}
      />
      {interval === SellingPlanInterval.YEAR && (
        <MemoedNumericSelect
          label="Month"
          labelHidden={labelHidden}
          options={monthOptions}
          onChange={(month: number) => onChange({ ...newAnchor, month })}
          disabled={!anchor}
          value={anchor?.month ?? 1}
          error={error}
        />
      )}
    </LegacyStack>
  )
}

export const cutoffOptions = mergeArrays(
  [{ label: 'Disabled', value: null } as OptionalNumericSelectOption],
  range(1, 365).map((i) => ({ value: i, label: `${i} ${pluralize(i, 'day')}` }))
)

export const preAnchorBehaviorLabels: Record<SellingPlanPreAnchorBehavior, string> = {
  ASAP: 'On checkout',
  NEXT: 'On anchor',
}

export const preAnchorBehaviorOptions: SelectOption[] = [
  { label: preAnchorBehaviorLabels.ASAP, value: 'ASAP' },
  { label: preAnchorBehaviorLabels.NEXT, value: 'NEXT' },
]

export const firstDeliveryTooltip = (
  <>
    <b>On checkout</b> &mdash; schedules the first delivery immediately after checkout. If the
    customer orders within the cutoff window at checkout, the first delivery is scheduled for the
    anchor date, and the second delivery is set for the next anchor date.
    <br />
    <b>On anchor</b> &mdash; schedules the first delivery on the nearest anchor day.
  </>
)

export const cutoffWindowTooltip = (
  <>
    Skips the first delivery if it's too close to the nearst anchor day.
    <br />
    <br />
    When <b>First delivery</b> is <b>On checkout</b>, it'll prevent two deliveries from being
    scheduled too close to each other.
    <br />
    <br />
    When <b>First delivery</b> is <b>On anchor</b>, it'll jump over to the subsequent anchor day, to
    give you enough time to prepare the order.
  </>
)

export const AnchorsEditModal = (props: {
  activator: DialogActivator
  anchors: SellingPlanAnchor[]
  interval: SellingPlanInterval
  preAnchorBehavior?: SellingPlanPreAnchorBehavior
  cutoff?: number | null
  onChange: (
    anchors: SellingPlanAnchor[],
    preAnchorBehavior?: SellingPlanPreAnchorBehavior,
    cutoff?: number | null
  ) => any
  pagePath: string
}) => {
  const hasFulfillmentOrderScopes = useShopFlag('has_fulfillment_order_scopes')

  const anchorType = intervalAnchorTypes[props.interval]
  const newAnchor = useMemo(
    () => ({
      day: 1,
      month: anchorType === 'YEARDAY' ? 1 : null,
      type: anchorType,
    }),
    [anchorType]
  )

  const initialAnchors = useMemo(
    () => (props.anchors.length > 0 ? props.anchors : [newAnchor]),
    [props.anchors, newAnchor]
  )

  const [anchors, setAnchors] = useState(initialAnchors)
  const [preAnchorBehavior, setPreAnchorBehavior] = useState(props.preAnchorBehavior)
  const [cutoff, setCutoff] = useState(props.cutoff)
  const [hasDuplicates, setHasDuplicates] = useState(false)

  useEffect(() => {
    setAnchors(initialAnchors)
    setPreAnchorBehavior(props.preAnchorBehavior)
    setCutoff(props.cutoff)
    setHasDuplicates(false)
  }, [props.activator, initialAnchors, props.preAnchorBehavior, props.cutoff])

  const handleAnchorChange = (anchor: SellingPlanAnchor, i: number) => {
    const newAnchors = [...anchors]
    newAnchors[i] = anchor
    setAnchors(newAnchors)
  }

  const handleAnchorRemove = (i: number) => {
    const newAnchors = [...anchors]
    newAnchors.splice(i, 1)
    setAnchors(newAnchors)
  }

  const isDuplicateAnchor = useCallback(
    (anchor: SellingPlanAnchor, i: number) => {
      return (
        anchors.findIndex((a, j) => j < i && a.day === anchor.day && a.month === anchor.month) !==
        -1
      )
    },
    [anchors]
  )

  const isValidForm = useCallback(() => {
    let res = true
    anchors.forEach((anchor, i) => {
      if (isDuplicateAnchor(anchor, i)) {
        res = false
        return
      }
    })
    return res
  }, [anchors, isDuplicateAnchor])

  const onSubmit = () => {
    if (!isValidForm()) {
      setHasDuplicates(true)
      return
    }
    props.onChange(anchors, preAnchorBehavior, cutoff)
    props.activator.close()
  }

  if (!hasFulfillmentOrderScopes) {
    return (
      <PrepaidScopesNeededModal activator={props.activator} pagePath={props.pagePath}>
        <p>To start using anchors, you need to accept additional permissions in Shopify</p>
      </PrepaidScopesNeededModal>
    )
  }

  return (
    <Modal
      open={props.activator.open}
      title="Edit anchors"
      onClose={props.activator.close}
      primaryAction={{
        content: 'Done',
        onAction: onSubmit,
      }}
      secondaryActions={[{ content: 'Cancel', onAction: props.activator.close }]}
      sectioned
      small
    >
      <FormLayout>
        {hasDuplicates && (
          <Banner status="critical">
            <Text as="p">Please fix duplicate anchors before submitting the form</Text>
          </Banner>
        )}

        {anchors.map((anchor, i) => (
          <HorizontalStack blockAlign="end" gap="2">
            <LegacyStack.Item fill>
              <AnchorField
                key={i}
                anchor={anchor}
                onChange={(anchor) => handleAnchorChange(anchor, i)}
                interval={props.interval}
                labelHidden={i > 0}
                error={isDuplicateAnchor(anchor, i)}
              />
            </LegacyStack.Item>
            <Button onClick={() => handleAnchorRemove(i)} destructive>
              Remove
            </Button>
          </HorizontalStack>
        ))}
        <HorizontalStack align="end">
          <Button onClick={() => setAnchors([...anchors, newAnchor])} plain>
            Add anchor
          </Button>
        </HorizontalStack>
        {preAnchorBehavior && (
          <MemoedSelect
            label={<HelpLabel tooltip={firstDeliveryTooltip}>First Delivery</HelpLabel>}
            options={preAnchorBehaviorOptions}
            onChange={(selected) => setPreAnchorBehavior(selected as SellingPlanPreAnchorBehavior)}
            value={preAnchorBehavior}
            disabled={anchors.length === 0}
          />
        )}
        {cutoff !== undefined && (
          <MemoedOptionalNumericSelect
            label={<HelpLabel tooltip={cutoffWindowTooltip}>Cutoff window</HelpLabel>}
            options={cutoffOptions}
            onChange={setCutoff}
            value={cutoff}
            disabled={anchors.length === 0}
          />
        )}
      </FormLayout>
    </Modal>
  )
}
