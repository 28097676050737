import {
  Button,
  FormLayout,
  HorizontalStack,
  LegacyStack,
  Modal,
  Tag,
  Text,
  VerticalStack,
} from '@shopify/polaris'
import { useCallback, useEffect, useState } from 'react'
import { mutate } from 'swr'

import { AnchorsEditModal } from '../common/AnchorsEditModal'
import { backendFetchResult, Result } from '../common/api'
import { ResultBanner, ResultToast, sortAnchors, useDialogActivator } from '../common/helpers'
import { SellingPlanInterval } from '../common/plan-models'
import {
  IntervalCountField,
  IntervalField,
  MaxCyclesField,
  MinCyclesField,
} from '../common/ScheduleFields'
import { FinishingBehaviorSelect } from '../common/subscription-fields'
import {
  defaultFinishingBehavior,
  FinishingBehavior,
  SubscriptionContract,
} from '../common/subscription-models'
import * as urls from '../common/urls'
import { PrePaidDeliveriesField } from '../selling-plan-page/PrePaidDeliveriesField'
import { anchorLabel } from './ScheduleCard'

interface Props {
  sc: SubscriptionContract
  open: boolean
  close: () => any
}

export const ScheduleEditDialog = ({ open, close, sc }: Props) => {
  const editAnchorsActivator = useDialogActivator()

  const [saving, setSaving] = useState(false)
  const [saveResult, setSaveResult] = useState<Result<any> | null>(null)

  const [deliveryIntervalCount, setDeliveryIntervalCount] = useState<number>(
    sc.gql_data.deliveryPolicy.intervalCount
  )
  const [prePaidDeliveries, setPrePaidDeliveries] = useState<number>(
    Math.round(sc.gql_data.billingPolicy.intervalCount / sc.gql_data.deliveryPolicy.intervalCount)
  )
  const billingIntervalCount = deliveryIntervalCount * prePaidDeliveries

  const [interval, setInterval] = useState<SellingPlanInterval>(sc.gql_data.billingPolicy.interval)
  const [minCycles, setMinCycles] = useState<number | null>(sc.gql_data.billingPolicy.minCycles)
  const [maxCycles, setMaxCycles] = useState<number | null>(sc.gql_data.billingPolicy.maxCycles)
  const [anchors, setAnchors] = useState(sortAnchors(sc.gql_data.deliveryPolicy.anchors ?? []))

  const [finishingBehavior, setFinishingBehavior] = useState<FinishingBehavior>(
    sc.pw_data.finishingBehavior ?? defaultFinishingBehavior
  )

  // Clear field error on change
  useEffect(() => {
    setSaveResult(null)
  }, [maxCycles])

  const save = useCallback(async () => {
    setSaving(true)

    const opts = {
      body: JSON.stringify({
        input: {
          billingPolicy: {
            anchors,
            intervalCount: billingIntervalCount,
            interval,
            minCycles,
            maxCycles,
          },
          deliveryPolicy: {
            anchors,
            intervalCount: deliveryIntervalCount,
            interval,
          },
        },
        pwData: {
          finishingBehavior,
        },
      }),
    }
    const resp = await backendFetchResult('PUT', `/subscriptions/${sc.rest_id}`, opts)

    setSaving(false)
    mutate(['subscription-contract', `${sc.rest_id}`])

    setSaveResult(resp)

    if (resp.status === 'success') {
      close()
    }
  }, [
    close,
    setSaving,
    sc,
    anchors,
    deliveryIntervalCount,
    billingIntervalCount,
    interval,
    minCycles,
    maxCycles,
    finishingBehavior,
  ])

  return (
    <>
      <Modal
        open={open && !editAnchorsActivator.open}
        title="Edit schedule"
        onClose={close}
        primaryAction={{ content: 'Save', onAction: save, loading: saving }}
        secondaryActions={[{ content: 'Cancel', onAction: close }]}
        sectioned
      >
        <FormLayout>
          <ResultBanner result={saveResult} setResult={setSaveResult} bottomMargin={false} />
          <LegacyStack distribution="fillEvenly" spacing="loose">
            <HorizontalStack blockAlign="end" gap="2">
              <LegacyStack.Item fill>
                <HorizontalStack align="space-evenly" gap="2">
                  <LegacyStack.Item fill>
                    <IntervalCountField
                      label="Delivery frequency"
                      value={deliveryIntervalCount}
                      onChange={setDeliveryIntervalCount}
                    />
                  </LegacyStack.Item>
                  <LegacyStack.Item fill>
                    <IntervalField
                      label="  "
                      value={interval}
                      onChange={(val: SellingPlanInterval) => {
                        setInterval(val)
                        setAnchors([])
                      }}
                    />
                  </LegacyStack.Item>
                </HorizontalStack>
              </LegacyStack.Item>
              {anchors.length === 0 && (
                <Button primary onClick={editAnchorsActivator.show}>
                  Add anchors
                </Button>
              )}
            </HorizontalStack>

            <PrePaidDeliveriesField
              value={prePaidDeliveries}
              onChange={setPrePaidDeliveries}
              pagePath={urls.subscriptionUrl(sc.rest_id)}
            />
          </LegacyStack>
          {anchors.length > 0 && (
            <VerticalStack gap="3">
              <HorizontalStack>
                <LegacyStack.Item fill>
                  <Text as="span">Anchors</Text>
                </LegacyStack.Item>
                <Button plain onClick={editAnchorsActivator.show}>
                  Edit anchors
                </Button>
              </HorizontalStack>
              <HorizontalStack wrap gap="1">
                {anchors.map((anchor, i) => (
                  <Tag key={i}>{anchorLabel(anchor)}</Tag>
                ))}
              </HorizontalStack>
            </VerticalStack>
          )}
          <FormLayout.Group>
            <MinCyclesField value={minCycles} onChange={setMinCycles} />
            <MaxCyclesField
              value={maxCycles}
              onChange={setMaxCycles}
              error={saveResult?.field === 'maxCycles' ? saveResult.message : false}
            />
          </FormLayout.Group>
          <FinishingBehaviorSelect onChange={setFinishingBehavior} value={finishingBehavior} />
        </FormLayout>
      </Modal>
      {editAnchorsActivator.open && (
        <AnchorsEditModal
          activator={editAnchorsActivator}
          anchors={anchors}
          interval={interval}
          onChange={(anchors, preAnchorBehavior, cutoff) => {
            setAnchors(sortAnchors(anchors))
          }}
          pagePath={urls.subscriptionUrl(sc.gid)}
        />
      )}

      <ResultToast result={saveResult} setResult={setSaveResult} />
    </>
  )
}
